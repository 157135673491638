.Toolbar {
    position: fixed;
    top: 0;
    left: 0;
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 100;
}

.Toolbar nav {
    height: 100%;
}

.menucontainer {
    display: inline-block;
    cursor: pointer;
    
  }
  
  .bar1, .bar2, .bar3 {
    width: 30px;
    height: 4px;
    background-color: white;
    margin: 5px 0;
    transition: 0.4s;
  }
  
  /* Rotate first bar */
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-5px, 5px) ;
    transform: rotate(-45deg) translate(-5px, 5px) ;
  }
  
  /* Fade out the second bar */
  .change .bar2 {
    opacity: 0;
  }
  
  /* Rotate last bar */
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-7px, -8px) ;
    transform: rotate(45deg) translate(-7px, -8px) ;
  }
