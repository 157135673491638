.cs_container{
	height: 100vh;
	overflow: hidden;
	background-color: black;
	.overlay{
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%; 
	    height: 100%;
	    background: rgba(0, 0, 0, .99);
	    transition: background 630ms ease-in;
	    overflow: hidden;
	    .about{
	    	width: 100%;
	    	position: absolute;
		    bottom: 0;
		    left: 0;
	    	font-size: 20px;
	    	color: white;
    		
    		.link{
    			width: 100%;
    			height: 100px;
    			position: relative;
    			button{
    				// margin-top: 48px;
    				position: absolute;
    				left: 50%;
    				transform: translateX(-50%);
				    align-items: center;
				    background: transparent;
				    border: none;
				    color: white;
				    display: inline-flex;
				    justify-content: center;
				    font-size: 16px;
				    font-weight: 600;
    				padding: 10px 26px;
				    letter-spacing: 0;
				    min-width: 75px;
				    opacity: .5;
				    text-align: center;
				    text-decoration: none;
				    transition: all 500ms cubic-bezier(0, 0.61, 0.28, 0.92), filter 500ms cubic-bezier(0, 0.61, 0.28, 0.92);
				    white-space: nowrap;
				    // border-radius: 50px;
				    border: 1px solid white;
				    a{
				    	text-decoration: none;
				    	color: inherit;
				    }
				    &:hover{
				    	color: transparent;
				    	background: white; 
				    	a{
					    	color: black;
					    }
				    }
    			}
    		}
    		.info{
    			
    		}
	    }
	}
	canvas{
    	// opacity: 0;
    	// transition: opacity 630ms ease-in 800ms;
    	background-image: url("/earth.png");
    	background-size: cover;
    	background-repeat: no-repeat;
    }
}

.cs_container.loaded{
	.overlay{
		background: rgba(0, 0, 0, .15);
	}
	// canvas{
 //    	opacity: 1;
 //    }
}


@media screen and (min-width: 960px){

	.cs_container{
		.overlay{
			.about{
				padding: 42px;
				.info{
					width: 55%;
				}
			}
		}
	}

}

@media screen and (max-width: 959px){

	.cs_container{
		.overlay{
			.about{
				padding: 25px;
				.info{
					width: 60%;
				}
			}
		}
	}

}

@media screen and (max-width: 639px){

	.cs_container{
		.overlay{
			.about{
				padding: 20px;
				.info{
					width: 85%;
				}
			}
		}
	}

}