.DrawerToggle {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-left: 12px;
    transform: translateY(50%);
    transition: transform 0.4s ease-out;
    z-index: 500;
    background-color: rgba(0, 0, 0, .5);
    overflow-y: scroll;
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(200%);
}

@media (max-width: 499px) {
    .DrawerToggle{
        ul{
            height: 100%;
            width: 100%;
            li{
                height: auto;
            }
        }
    }
}