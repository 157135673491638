
button:disabled{
  // color: #555;
  color: white;
  cursor: not-allowed;
}
form{
  display: flex;
  width: 100%;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  button {
    position: fixed;
    right: 40px;
    bottom: 35px;
    align-self: flex-end;
    background-color: transparent;
    font-size: 1.5em;
    border: transparent;
    color: #555;
    cursor: pointer;
  }
}

input {
  width: 100%;
  font-family: "freehand";
  font-size: 2rem;
  padding: 7px;
  // background-color: black;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgb(197, 197, 197);
  color: #555;
}

input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid white;
}

.CardContainer{
  height: 100vh;
  width: 100vw;
  background-image: url("/contactbackground2.png");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "freehand";
  // overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  h1{
    font-family: "freehand";
    padding-top: 100px;
  }
}

.container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 550px;
  width: 777px;
  border-radius: 2px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  box-sizing: border-box;
  // overflow: hidden;
}

.CardInfo {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    h1{
        height: 50%;
        margin: 0;
    }
    h2{
        height: 30%;
    }
    form{
      display: flex;
      flex-flow: row wrap;
      height: 100%;
      .message{
        width: 50%;
        height: 100%;
        color: #555;
        align-content: flex-start;
        padding: 0 20px 0 20px;
        padding-top: 100px;
        overflow-wrap: break-word; 
        box-sizing: border-box;
        input{
          border: none;
          overflow-x: hidden;
          overflow-wrap: break-word;
          
        }
      }
      .info{
        width: 45%;
        height: 85%;
        align-content: flex-end;
        padding: 0 20px 0 20px;
        padding-top: 30%;
        border-left: 2px solid rgb(197, 197, 197);
        box-sizing: border-box;
        input{
          font-size: 1.5rem;
        }
      }
    }  
}


.backtop {
    width: 100%;
    height: 50%;
}

.backbottom {
    width: 100%;
    height: 50%;
}


*{
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  h1{
    font-size: 2.5rem;
    font-family: 'Montserrat';
    font-weight: normal;
    text-align: center;
    margin: 2rem 0;
}
  
   
  .front,
  .back{
      -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      text-align: center;
      height: 100%;
      color: #fff;
      box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
  }
  
  .front:after{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    opacity: 1;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 2px;
    box-sizing: border-box;
    
  }
  // .front{
  //   -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  //   transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  //   -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  //   transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  //   transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  // }
  
  
  .back{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .front{
    .inner{
      -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
              transform: translateY(-50%) translateZ(60px) scale(0.94);
      
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      outline: 1px solid transparent;
      -webkit-perspective: inherit;
              perspective: inherit;
      z-index: 2;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      box-sizing: border-box;
    }
  }
  
  
  .container .startback{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            overflow: hidden;
  }

.container{
    .startfront{
            -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            overflow: hidden;
    }
} 

.flippedback{
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.flippedfront{
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.front .inner p{
  font-size: 2rem; 
}

.front .inner p:after{
  content: '';
  width: 4rem;
  height: 2px;
  position: absolute;
  background: #C6D4DF;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
}

.front .inner span{
  color: rgba(255,255,255,0.7);
  font-family: 'Montserrat';
  font-weight: 300;
}
  
@media screen and (min-width:959px)  {
  .front{
    background-image: url("/postcard.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .back{
    background-image: url("/postcardback.png");
    background-size: cover;
    background-repeat: no-repeat;
    .inner{
      h1{
        display: none;
      }
      h2{
        display: none;
      }
    }
  }
}

@media screen and (max-width:959px)  {
  button:disabled{
      color: #232323;
  }

  button:enabled{
    color: white;
  }
  .container{
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
  .front{
    background-color: black;
  }
  .back{
    background-color: black;
    display: table;
    .inner{
      display: table-cell;
      color: white;
      margin: 0 auto;
      vertical-align: middle;
      text-align: center;
      h1{
        margin: 0;
        padding: 0;
      }
    }
  }
  
  .CardInfo {
    form{
      .message{
        width: 100%;
        height: 45%;
        padding: 0;
        padding-top: 30px;
      }
      .info{
        border: 0;
        width: 100%;
        height: 55%;
        padding: 0;
        padding-top: 2%;
        input{
          font-size: 24px;
        }
      }
      button{
        right: 10px;
        bottom: 5px;
        background-color: black;
        padding: 7px;
        font-size: 25px;
      }
    }
  }
  input{
    color: white;
    font-size: 24px;
    padding: 5px;
  }

}

@media screen and (max-width:500px) {
  .CardInfo {
    form{
      button{
        right: 40px;
        bottom: 65px;
      }
    }
  }
 }