.Scroll {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 200;
    left: 0;
    top: 0;
    background-color: black;
    overflow: hidden;
    transition: all 2s ease-out 6s;
    img{
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.exiting
{
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -o-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition-duration: 2s;
    -moz-transition-duration: 2s;
    -o-transition-duration: 2s;
    opacity: 0;
               
}
