.warehouseAbout{
    margin-top: 900px;
    z-index: 5;
    width: 100%;
    height: auto;
}

.scroll_text{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    color: white;
    transform: translate(-50%, -50%);
    h1{
        font-family: "druk";
        font-size: 65px;
        letter-spacing: .1rem;
    }
}

.lift{
    display: flex;
    width: 100%;
    // height: 900px;
    padding-top: 5%;
    padding-bottom: 10%;
    background-color: #121217;
    .lift_left{
        display: inline-flex;
        width: 50%;
        height: auto;
        padding: 0 5%;
        p{
            color: #858585;
            font-family: monospace;
            font-size: 30px;
            margin: auto;
        }
        img{
            height: 25%;
        }
    }
    .lift_right{
        position: relative;
        display: inline-flex;
        width: 50%;
        height: 600px;
        max-height: 600px;
        background-image: url('/wh.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        .lift_right_img{
            margin: auto;
            img{
                height: 100%;
                width: 100%;
            }
        }
    }
}
.lift_right:after{
    width:100%;
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    height:100%;
    background: radial-gradient(rgba(23,23,18, 0), #121217);
}

.icon{
    height: 100%;
    width: 25%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.lift_icons{
    background-color: #121217;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: space-around;
    padding: 75px 10px;
    .icons_csharp{
        background-image: url('/csharp-icon.png');
    }
    .icons_blender{    
        background-image: url('/blender-icon.png');
    }
    .icons_unity{
        background-image: url('/unity-icon.png');
    }
    .icons_oculus{
        background-image: url('/oculus-icon.png');
        filter: drop-shadow(0px 0px 1px gray);
    }
}

.beforediv{
    position: relative;
    width: 100%;
    height: 100vh;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(top,  rgba(18,18,23,0) 0%, rgba(18,18,23,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(18,18,23,0) 0%, rgba(18,18,23,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(18,18,23,0) 0%, rgba(18,18,23,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
     -ms-animation: fadein 2s; /* Internet Explorer */
      -o-animation: fadein 2s; /* Opera < 12.1 */
         animation: fadein 2s;
}

.liftinfo{
    // padding: 0 10%;
    background-color: #17171d;
    img{
        display: block;
        height: 100%;
        width: 100%;
        margin: auto;
    }
}
.liftsect{
    display: flex;
    width: 100%;
    background-color: #121217;
    color: rgb(133, 133, 133);
    overflow: hidden;
    .liftsect_left{
        position: relative;
        display: inline-flex;
        width: 50%;
        height: 100%;
        img{
            padding: 125px 0;
            width: 100%;
        }
    }
    
    .liftsect_right{
        display: inline-flex;
        width: 50%;
        padding: 100px 100px;
        font-size: 3em;
        p{
            margin: auto;
        }
    }
}
.liftsect_left:after{
    width:100%;
  content:'';
  display:block;
  position:absolute;
  top:0;
  left:0;
  background: linear-gradient(to left, rgba(18,18,23,1) 0%,rgba(255,255,255,0) 25%);
  height:100%;
}
.unity{
    height: 100vh;
    background-color: rgb(23, 23, 29);
    background-image: url('/unity.png');
    background-repeat: no-repeat;
    background-position: center;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media (max-width: 950px) {

    .scroll_text{
        h1{
            font-size: 50px;
        }
    }
    .lift{
        .lift_left{
            padding: 0 10px;
            p{
                font-size: 18px;
            }
        }
        .lift_right{
            height: 300px;
            .lift_right_img{
                img{
                    height: 50%;
                    width: 50%;
                }
            }
        }
    }
    .liftinfo{
        img{
            width: 95%;
        }
    }
    .liftsect{
        padding: 15px;
        .liftsect_left{
            img{
                height: 50%;
            }
        }
        .liftsect_right{
            padding: 5px 10px;
            p{
               font-size: 18px;
            }
        }
    }
    .icon{
        width: 25%;
    }
    .lift_icons{
        height: 150px;
        padding: 30px 10px;
    }
    .unity{
        height: 350px;
        background-size: 60%;
    }
}

@media (max-width: 500px) {
    .lift{
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        .lift_left{
            width: 100%;
            order: 2;
            padding: 15px 10px;
        }
        .lift_right{
            width: 100%;
            order: 1;
        }
    }
    .liftsect{
        padding: 0;
    }
    .icon{
        width: 20%;
    }
    .lift_icons{
        padding: 15px;
    }
    .unity{
        height: 250px;
        background-size: 75%;
    }
}