.main {
    width: 100%;
    height: 100vh;
    background-color: black;
    video{
        display: none;
    }
   
}.mobileOverlay{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30vw;
    height: 25vh;
    background-color: rgba(95, 95, 95, 0.5);;
}

.overlay{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(95, 95, 95, 0.5);
    border-radius: 15px;
    img{
        width: 100%;
        height: auto;
    }
}

.btn{
    width: 100px;
    height: 75px;
}