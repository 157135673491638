.covidcontainer{
    .covidoverlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        overflow: hidden;
        .covid_info{
            position: absolute;
            bottom: 0;
            left: 0;
            .badges{
                width: 100%;
                button{
                    border: 1px solid white;
                    background: transparent;
                    float: right;
                    a{
                        text-decoration: none;
                        color: white;
                    }
                } 
            }
            .about{
                color: white;
                font-family: monospace;
                a{
                    color: white;
                }
            }
        }
    }
    video{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto;
        z-index: -1;
    }
}


@media only screen and (min-width: 960px) {
    .covidcontainer{
        .covidoverlay{
            .covid_info{
                width: 55%;
                padding: 35px;
                .badges{
                    margin-bottom: 20px;
                    button{
                        height: 45px;
                        width: 60px;
                    }
                    img{
                        height: 45px;
                    }
                }
                .about{
                    font-size: 24px;
                }
            }
        }
    }
}

@media only screen and (max-width:959px)  {

    .covidoverlay{
        .covid_info{
            width: 60%;
            padding: 10px 25px;
            .badges{
                margin-bottom: 15px;
                button{
                    height: 40px;
                    width: 60px;
                }
                img{
                    height: 35px;
                }
            }
            .about{
                font-size: 18px;
            }
        }
    }
    
}

@media screen and (max-width:639px) { 
    .covidoverlay{
        .covid_info{
            width: 85%;
            padding: 10px 20px;
            .badges{
                margin-bottom: 5px;
                img{
                    height: 25px
                }
                button{
                    height: 25px;
                    width: 45px;
                }
            }
            .about{
                font-size: 16px;
            }
        }
    }
 }
